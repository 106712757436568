import React from 'react';
import ShiftsReports from './components/shifts/shiftsReports';

const Reports = () => {
  return (
    <div>
      <div>REPORTS</div>
      <div>
        <ShiftsReports />
      </div>
    </div>
  );
};

export default Reports;
